import Customer from '../../authentication/models/Customer';
import {getAdminNotificationsQuery} from '../models/getAdminNotificationsQuery';
import {NotificationModel} from '../models/NotificationModel';

export const NOTIFICATION_ACTION = 'NOTIFICATION_ACTION';
export interface NotificationVerificationAction {
  type: typeof NOTIFICATION_ACTION;
  payload: {
    customer: Customer;
  };
}

export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export interface GetUserNotificationsAction {
  type: typeof GET_USER_NOTIFICATIONS;
}
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export interface GetUserNotificationsSuccessAction {
  type: typeof GET_USER_NOTIFICATIONS_SUCCESS;
  payload: {[notification_id: string]: NotificationModel};
}
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export interface UpdateNotificationsAction {
  type: typeof UPDATE_NOTIFICATIONS;
  payload: {[notification_id: string]: NotificationModel};
}

export const ACKNOWLEDGE_NOTIFICATION = 'ACKNOWLEDGE_NOTIFICATION';
export interface AcknowledgeNotificationAction {
  type: typeof ACKNOWLEDGE_NOTIFICATION;
  payload: NotificationModel[];
}

export const GET_ADMIN_NOTIFICATIONS = 'GET_ADMIN_NOTIFICATIONS';
export interface GetAdminNotificationsAction {
  type: typeof GET_ADMIN_NOTIFICATIONS;
  payload: getAdminNotificationsQuery | null;
}

export const GET_ADMIN_NOTIFICATIONS_SUCCESS =
  'GET_ADMIN_NOTIFICATIONS_SUCCESS';
export interface GetAdminNotificationsSuccessAction {
  type: typeof GET_ADMIN_NOTIFICATIONS_SUCCESS;
  payload: {[notification_id: string]: NotificationModel} | null;
}

export const RETRY_SEND_NOTIFICATION = 'RETRY_SEND_NOTIFICATION';
export interface retrySendNotificationAction {
  type: typeof RETRY_SEND_NOTIFICATION;
  payload: {
    notificationId: string;
  };
}

export const RETRY_SEND_NOTIFICATION_SUCCESS =
  'RETRY_SEND_NOTIFICATION_SUCCESS';
export interface retrySendNotificationSuccessAction {
  type: typeof RETRY_SEND_NOTIFICATION_SUCCESS;
  payload: {
    notification: NotificationModel;
  };
}

export const CANCEL_NOTIFICATION = 'CANCEL_NOTIFICATION';
export interface CancelNotificationAction {
  type: typeof CANCEL_NOTIFICATION;
  payload: {
    notificationId: string;
  };
}
export const CANCEL_NOTIFICATION_SUCCESS = 'CANCEL_NOTIFICATION_SUCCESS';
export interface CancelNotificationSuccessAction {
  type: typeof CANCEL_NOTIFICATION_SUCCESS;
  payload: NotificationModel;
}

export type SharedNotificationsActionsType =
  | NotificationVerificationAction
  | GetUserNotificationsAction
  | GetUserNotificationsSuccessAction
  | UpdateNotificationsAction
  | AcknowledgeNotificationAction
  | GetAdminNotificationsAction
  | GetAdminNotificationsSuccessAction
  | retrySendNotificationAction
  | retrySendNotificationSuccessAction
  | CancelNotificationAction
  | CancelNotificationSuccessAction;

export const SharedNotificationsActions = {
  cancelNotification: (notificationId: string): CancelNotificationAction => {
    return {
      type: CANCEL_NOTIFICATION,
      payload: {
        notificationId,
      },
    };
  },
  cancelNotificationSuccess: (
    notification: NotificationModel,
  ): CancelNotificationSuccessAction => {
    return {
      type: CANCEL_NOTIFICATION_SUCCESS,
      payload: notification,
    };
  },
  notificationVerification: (
    customer: Customer,
  ): NotificationVerificationAction => {
    return {
      type: NOTIFICATION_ACTION,
      payload: {
        customer,
      },
    };
  },

  getUserNotifications: (): GetUserNotificationsAction => {
    return {
      type: GET_USER_NOTIFICATIONS,
    };
  },
  getUserNotificationsSuccess: (notifications: {
    [notification_id: string]: NotificationModel;
  }): GetUserNotificationsSuccessAction => {
    return {
      type: GET_USER_NOTIFICATIONS_SUCCESS,
      payload: notifications,
    };
  },

  acknowledgeNotifications: (
    notifications: NotificationModel[],
  ): AcknowledgeNotificationAction => {
    return {
      type: ACKNOWLEDGE_NOTIFICATION,
      payload: notifications,
    };
  },
  updateNotifications: (notifications: {
    [notification_id: string]: NotificationModel;
  }): UpdateNotificationsAction => {
    return {
      type: UPDATE_NOTIFICATIONS,
      payload: notifications,
    };
  },
  getAdminNotifications: (
    reportJob: getAdminNotificationsQuery | null,
  ): GetAdminNotificationsAction => {
    return {
      type: GET_ADMIN_NOTIFICATIONS,
      payload: reportJob,
    };
  },

  getAdminNotificationsSuccess: (
    notifications: {[notification_id: string]: NotificationModel} | null,
  ): GetAdminNotificationsSuccessAction => {
    return {
      type: GET_ADMIN_NOTIFICATIONS_SUCCESS,
      payload: notifications,
    };
  },

  retrySendNotification: (
    notificationId: string,
  ): retrySendNotificationAction => {
    return {
      type: RETRY_SEND_NOTIFICATION,
      payload: {
        notificationId,
      },
    };
  },

  retrySendNotificationSuccess: (
    notification: NotificationModel,
  ): retrySendNotificationSuccessAction => {
    return {
      type: RETRY_SEND_NOTIFICATION_SUCCESS,
      payload: {
        notification,
      },
    };
  },
};
