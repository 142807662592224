// import {Platform as NativePlatform} from 'react-native';
// import DeviceInfo from 'react-native-device-info';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
  firestoreAuthService,
  getFirestore,
} from '../../core/services/FirestoreService';

// import messaging, {
//   FirebaseMessagingTypes,
// } from '@react-native-firebase/messaging';
import Customer from '../../authentication/models/Customer';
import {sharedCommonActions} from '../../common/redux/SharedCommonActions';
import {getCurrentUserResponse} from '../../core/models/GetCurrentUser';
import {getErrorMessage, logger} from '../../core/services/LogService';
import {NotificationModel} from '../models/NotificationModel';
import NotificationStatus from '../models/NotificationStatus';
import notificationApiService from '../services/NotificationApiService';
import sharedNotificationsService from '../services/SharedNotificationsService';
import {
  CANCEL_NOTIFICATION,
  CancelNotificationAction,
  GET_ADMIN_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS,
  GetAdminNotificationsAction,
  RETRY_SEND_NOTIFICATION,
  retrySendNotificationAction,
  SharedNotificationsActions,
} from './NotificationsActions';

export default function* sharedNotificationSaga() {
  yield all([
    // takeEvery(NOTIFICATION_ACTION, addNotificationSaga),
    takeEvery(GET_USER_NOTIFICATIONS, getUserNotification),
    // takeEvery(ACKNOWLEDGE_NOTIFICATION, acknowlegeNotificationSaga),
    takeEvery(GET_ADMIN_NOTIFICATIONS, getAdminNotifications),
    takeEvery(RETRY_SEND_NOTIFICATION, retrySendNotificationSaga),
    takeEvery(CANCEL_NOTIFICATION, cancelNotificationSaga),
  ]);
}

function* cancelNotificationSaga(action: CancelNotificationAction) {
  try {
    yield put(sharedCommonActions.fetchStart('cancelNotification'));
    let token = '';
    const currentUser: getCurrentUserResponse | null = yield call(
      firestoreAuthService.getCurrentUser.bind(firestoreAuthService),
    );

    if (currentUser && currentUser.token.token) {
      token = currentUser.token.token;
    }

    const notification: NotificationModel = yield call(
      notificationApiService.cancelNotification.bind(notificationApiService),
      token,
      action.payload.notificationId,
    );

    yield put(
      SharedNotificationsActions.cancelNotificationSuccess(notification),
    );
    yield put(sharedCommonActions.fetchSuccess('cancelNotification'));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    logger.error('Error canceling notification:', errorMessage);
    yield put(
      sharedCommonActions.fetchError('cancelNotification', errorMessage),
    );
  }
}
// function* addNotificationSaga(action: NotificationVerificationAction) {
//   try {
//     const {customer} = action.payload;
//     try {
//       const permission: FirebaseMessagingTypes.AuthorizationStatus = yield call(
//         messaging().hasPermission.bind(messaging()),
//       );

//       if (permission === messaging.AuthorizationStatus.NOT_DETERMINED) {
//         const authStatus: FirebaseMessagingTypes.AuthorizationStatus =
//           yield call(messaging().requestPermission.bind(messaging()));

//         const enabled =
//           authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
//           authStatus === messaging.AuthorizationStatus.PROVISIONAL;

//         if (enabled) {
//           console.log('Authorization status:', authStatus);
//         }
//       } else {
//         console.log('Authorization status:', permission);
//       }
//     } catch (error: any) {
//       console.error('Error handling permission:', error);
//     }

//     try {
//       /*if (NativePlatform.OS === Platform.IOS) {
//                 console.log('Platform iOS, needs to register device');
//                 yield call(messaging().registerDeviceForRemoteMessages.bind(messaging()));
//             }*/
//       const token: string = yield call(messaging().getToken.bind(messaging()));
//       const deviceId: string = yield call(
//         DeviceInfo.getUniqueId.bind(DeviceInfo),
//       );
//       const deviceNotificationAuthorization: AppChannelAuthorization = {
//         authorization_token: token,
//         platform: NativePlatform.OS as 'ios' | 'android',
//         updated_at: new Date(),
//       };
//       if (!customer.notification) {
//         customer.notification = {};
//       }
//       if (!customer.notification[NotificationChannelTypes.APP]) {
//         customer.notification = {
//           ...customer.notification,
//           [NotificationChannelTypes.APP]: {
//             disabled: false,
//             authorization: {
//               [deviceId]: deviceNotificationAuthorization,
//             },
//             updated_at: new Date(),
//           },
//         };
//       } else {
//         customer.notification[NotificationChannelTypes.APP] = {
//           disabled:
//             customer.notification[NotificationChannelTypes.APP].disabled,
//           authorization: {
//             ...customer.notification[NotificationChannelTypes.APP]
//               .authorization,
//             [deviceId]: deviceNotificationAuthorization,
//           },
//           updated_at: new Date(),
//         };
//       }
//       yield call(updateCustomer, customer);
//     } catch (error: any) {
//       console.error('Error getting token:', error);
//     }
//   } catch (error: any) {
//     console.error('Error adding notification:', error);
//   }
// }

function* getUserNotification(status?: NotificationStatus[]) {
  let token = '';
  const currentUser: getCurrentUserResponse | null = yield call(
    firestoreAuthService.getCurrentUser.bind(firestoreAuthService),
  );

  if (currentUser && currentUser.token.token) {
    token = currentUser.token.token;
  }

  if (token) {
    const notifications: {[notification_id: string]: NotificationModel} =
      yield call(
        sharedNotificationsService.getUserNotifications.bind(
          sharedNotificationsService,
        ),
        token,
        status,
      );

    yield put(
      SharedNotificationsActions.getUserNotificationsSuccess(notifications),
    );
  }
}

// function* acknowlegeNotificationSaga(action: AcknowledgeNotificationAction) {
//   try {
//     const notifications = action.payload;
//     let token = '';
//     const currentUser: getCurrentUserResponse | null = yield call(
//       firestoreAuthService.getCurrentUser,
//     );

//     if (currentUser && currentUser.token.token) {
//       token = currentUser.token.token;
//     }
//     if (token) {
//       const notificationsUpdated: {
//         [notification_id: string]: NotificationModel;
//       } = yield call(
//         notificationApiService.updateNotificationStatus.bind(
//           notificationApiService,
//         ),
//         token,
//         notifications,
//       );
//       console.log('====================================');
//       console.log(notificationsUpdated);
//       console.log('====================================');
//       yield put(
//         SharedNotificationsActions.updateNotifications(notificationsUpdated),
//       );
//     }
//   } catch (error: any) {
//     console.error('Error acknowledging notification:', error);
//   }
// }

async function updateCustomer(customer: Customer) {
  await getFirestore()
    .collection('customers')
    .doc(customer.id)
    .set(customer, {merge: true});
}

function* getAdminNotifications(action: GetAdminNotificationsAction) {
  try {
    yield put(sharedCommonActions.fetchStart('getReportNotifications'));
    const queries = action.payload;
    let token = '';

    const currentUser: getCurrentUserResponse | null = yield call(
      firestoreAuthService.getCurrentUser.bind(firestoreAuthService),
    );

    if (currentUser && currentUser.token.token) {
      token = currentUser.token.token;
    }

    if (!token) {
      throw new Error('No token found');
    }

    if (queries) {
      const notifications: {[notification_id: string]: NotificationModel} =
        yield call(
          notificationApiService.getAdminNotifications.bind(
            notificationApiService,
          ),
          token,
          queries,
        );
      yield put(
        SharedNotificationsActions.getAdminNotificationsSuccess(notifications),
      );
    } else {
      yield put(SharedNotificationsActions.getAdminNotificationsSuccess(null));
    }

    yield put(sharedCommonActions.fetchSuccess('getReportNotifications'));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error);
    logger.error('Error getting notifications:', errorMessage);
    yield put(
      sharedCommonActions.fetchError('getReportNotifications', errorMessage),
    );
  }
}

function* retrySendNotificationSaga(action: retrySendNotificationAction) {
  try {
    yield put(sharedCommonActions.fetchStart('retrySendNotification'));

    const notificationId = action.payload.notificationId;

    let token = '';

    const currentUser: getCurrentUserResponse | null = yield call(
      firestoreAuthService.getCurrentUser.bind(firestoreAuthService),
    );

    if (currentUser && currentUser.token.token) {
      token = currentUser.token.token;
      console.log('Current user token:', token);
    }

    if (!token) {
      throw new Error('No token found');
    }

    const notificationsSended: NotificationModel = yield call(
      notificationApiService.retrySendNotification.bind(notificationApiService),
      token,
      notificationId,
    );

    yield put(
      SharedNotificationsActions.retrySendNotificationSuccess(
        notificationsSended,
      ),
    );
    yield put(sharedCommonActions.fetchSuccess('retrySendNotification'));
  } catch (error: any) {
    const error_message = getErrorMessage(error);
    logger.error('Error retrying send notification:', error_message);
    yield put(
      sharedCommonActions.fetchError('retrySendNotification', error_message),
    );
  }
}
