export const adminReportTranslationsEn = {
  'admin.report.raw_data.title': 'Raw Data',
  'admin.report.vizualization.title': 'Visualization',
  'admin.report.path.report_not_found': 'Report not found',
  'admin.report.modal.title': 'Report',
  'admin.report.status.success': 'Success',
  'admin.report.status.sent': 'Sent',
  'admin.report.status.confirmed': 'Confirmed',
  'admin.report.status.sending': 'Sending',
  'admin.report.status.failed': 'Failed',
  'admin.report.status.error': 'Error',
  'admin.report.status.pending': 'Pending',
  'admin.report.status.processing': 'Processing',
  'admin.report.status.cancelled': 'Cancelled',
  'admin.report.status.unknown': 'Unknown',
  'admin.report.back_to_list': 'Back to list',
  'admin.report.title': 'Report',
  'admin.report.notifications.status': 'Status',
  'admin.report.notifications.notification_channel': 'Notification Channel',
  'admin.report.notifications.created_at': 'Creation Date',
  'admin.report.notifications.updated_at': 'Update Date',
  'admin.report.notifications.sended_at': 'Send Date',
  'admin.report.notifications.title': 'Notifications',
  'admin.report.notifications.user': 'User',
  'admin.report.notifications.recipient': 'Recipient',
};
