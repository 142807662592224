import {
  CANCEL_NOTIFICATION_SUCCESS,
  GET_ADMIN_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_SUCCESS,
  RETRY_SEND_NOTIFICATION_SUCCESS,
  SharedNotificationsActionsType,
  UPDATE_NOTIFICATIONS,
} from './NotificationsActions';
import NotificationsState from './NotificationsState';

const initialState: NotificationsState = {
  notifications: null,
  adminNotifications: null,
};

/**
 * Reducer function for managing notifications in the application state.
 *
 * @param {NotificationsState} state - The current state of the notifications.
 * @param {SharedNotificationsActionsType} action - The action object that is dispatched.
 * @return {NotificationsState} The new state of the notifications after handling the action.
 */
const sharedNotificationsReducer = (
  state: NotificationsState = initialState,
  action: SharedNotificationsActionsType,
): NotificationsState => {
  switch (action.type) {
    case GET_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload,
        },
      };
    case GET_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        adminNotifications: action.payload,
      };
    case RETRY_SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        adminNotifications: {
          ...state.adminNotifications,
          [action.payload.notification.id]: action.payload.notification,
        },
      };
    case CANCEL_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        adminNotifications: {
          ...state.adminNotifications,
          [action.payload.id]: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default sharedNotificationsReducer;
