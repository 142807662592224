import PixagriScopes from '../../core/models/PixagriScopes';
import { BaseNotificationApiService } from '../../core/services/apiServices/BaseNotificationApiService';
import { getAdminNotificationsQuery } from '../models/getAdminNotificationsQuery';
import { NotificationModel } from '../models/NotificationModel';
import NotificationStatus from '../models/NotificationStatus';

export class NotificationApiService extends BaseNotificationApiService {
    EXECUTE_ENDPOINT = '/execute';
    CONFIGURATION_ENDPOINT = '/configurations';
    UPDATE_STATUS_ENDPOINT = '/acknowledge';
    ALLOWED_ENDPOINT = '/allowed';
    RETRY_ENDPOINT = '/retry';
    CANCEL_NOTIFICATION_ENDPOINT = '/cancel';
    STATUS_QUERY_PARAM = 'status';


    getNotificationUrl() {
        return `${this.getBaseUrl()}/${PixagriScopes.NOTIFICATION}`;
    }

    getCancelNotificationUrl() {
        return `${this.getNotificationUrl()}${this.CANCEL_NOTIFICATION_ENDPOINT}`;
    }

    getNotificationExecuteUrl() {
        return `${this.getNotificationUrl()}${this.EXECUTE_ENDPOINT}`;
    }

    getNotificationConfigurationsUrl() {
        return `${this.getNotificationUrl()}${this.CONFIGURATION_ENDPOINT}`;
    }

    updateNotificationStatusUrl() {
        return `${this.getNotificationUrl()}${this.UPDATE_STATUS_ENDPOINT}`;
    }

    getAllowedNotificationsUrl() {
        return `${this.getNotificationUrl()}${this.ALLOWED_ENDPOINT}`;
    }

    async getUserNotifications(token: string, status?: NotificationStatus[]) {
        let url = this.getNotificationUrl();
        if (status && status.length) {
            url += `?${this.STATUS_QUERY_PARAM}=${status.join(',')}`;
        }

        const res = await this.sendRequest<NotificationModel[]>(
            token,
            url,
            'GET',
            null,
            true,
        );

        if (res.error) {
            throw new Error(res.error_message);
        }
        return res.response;
    }
    //   chunkList(item: any[], chunkSize: number) {
    //     const chunkedArray = [];
    //     for (let i = 0; i < item.length; i += chunkSize) {
    //       const chunk = item.slice(i, i + chunkSize);
    //       chunkedArray.push(chunk);
    //     }
    //     return chunkedArray;
    //   }
    //   async updateNotificationStatus(
    //     token: string,
    //     notificationsList: NotificationModel[],
    //   ): Promise<{[notification_id: string]: NotificationModel}> {
    //     let url = this.updateNotificationStatusUrl();
    //     const chunkedArray = [];
    //     for (let i = 0; i < notificationsList.length; i += 5) {
    //       const chunk = notificationsList.slice(i, i + 5);
    //       chunkedArray.push(chunk);
    //     }
    //     const updatePromises: Promise<ApiSendRequestResult<NotificationModel[]>>[] =
    //       [];
    //     chunkedArray.forEach((chunk) => {
    //       updatePromises.push(
    //         this.sendRequest<NotificationModel[]>(token, url, 'POST', chunk),
    //       );
    //     });
    //     const responses = await Promise.all(updatePromises);

    //     const notificationUpdated: NotificationModel[][] = [];

    //     responses.forEach((res) => {
    //       if (!res.error) {
    //         notificationUpdated.push(res.response);
    //       } else {
    //         logger.error(`Cannot update one chunk of notification`, {
    //           error: res.error_message,
    //         });
    //       }
    //     });
    //     const allNotificationsList = notificationUpdated.flat();
    //     const notificationMapUpdated: {
    //       [notification_id: string]: NotificationModel;
    //     } = {};
    //     allNotificationsList.forEach((notification) => {
    //       notificationMapUpdated[notification.id] = notification;
    //     });

    //     return notificationMapUpdated;
    //   }

    async cancelNotification(token: string, notifcationId: string): Promise<NotificationModel> {
        const url = this.getCancelNotificationUrl();
        const res = await this.sendRequest<NotificationModel>(token, url, 'POST', { notification_id: notifcationId });

        if (res.error) {
            throw new Error(res.error_message);
        }
        return res.response;
    }
    async getAdminNotifications(
        token: string,
        queries: getAdminNotificationsQuery,
    ): Promise<{ [notification_id: string]: NotificationModel }> {
        const baseUrl = this.getAllowedNotificationsUrl();

        let url = baseUrl;
        Object.entries(queries).forEach((query) => {
            url = `${url}?${query[0]}=${query[1]}`;
        });

        const res = await this.sendRequest<{
            [notification_id: string]: NotificationModel;
        }>(token, url, 'GET');

        if (res.error) {
            throw new Error(res.error_message);
        }

        return res.response;
    }

    async retrySendNotification(
        token: string,
        notificationId: string,
    ): Promise<NotificationModel> {
        const url = `${this.getNotificationUrl()}${this.RETRY_ENDPOINT}`;

        const res = await this.sendRequest<NotificationModel>(token, url, 'POST', {
            notificationId: notificationId,
        });

        if (res.error) {
            throw new Error(res.error_message);
        }

        return res.response;
    }
}

const notificationApiService = new NotificationApiService();
export default notificationApiService;
