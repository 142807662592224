enum ReportQueriesKey {
  PROCESSING_ENGINE_JOB_ID = 'processing_engine_job_id',
  CAMPAIGN_YEAR_QUERY = 'campaign_id',
  ReportJobType = 'type',
  REPORT_ID = 'report_id',
  PRODUCER_REF = 'producer_ref',
  LIMIT = 'limit',
  DATE = 'date',
}

export default ReportQueriesKey;
