import log from 'loglevel';

export const isProdEnv = process.env.REACT_APP_ENVIRONMENT_NAME === 'prod';

const firebaseConfig = isProdEnv
  ? {
      apiKey: 'AIzaSyAkPkjHThYBhtGKi2xkGVTEgmwxyOs-r_M',
      authDomain: 'terranis-biz-shared-infra-prod.firebaseapp.com',
      projectId: 'terranis-biz-shared-infra-prod',
      storageBucket: 'terranis-biz-shared-infra-prod.appspot.com',
      messagingSenderId: '320334050617',
      appId: '1:320334050617:web:bec5380baad6a1f611a4e8',
    }
  : {
      apiKey: 'AIzaSyCYqmgQxaWtFI9fusYKotxGpEfu3We9RhQ',
      authDomain: 'terranis-biz-shared-infra-dev.firebaseapp.com',
      databaseURL: 'https://terranis-biz-shared-infra-dev.firebaseio.com',
      projectId: 'terranis-biz-shared-infra-dev',
      storageBucket: 'terranis-biz-shared-infra-dev.appspot.com',
      messagingSenderId: '152852416064',
      appId: '1:152852416064:web:a66637d649ab50a3be01a5',
    };

export const getTerranisSharedApiEndpoint = (): string => {
  if (isProdEnv) {
    return process.env.REACT_APP_TERRANIS_SHARED_API_URL!;
  } else {
    return process.env.REACT_APP_TERRANIS_SHARED_API_DEV_URL!;
  }
};

export const getTerranisOauthAuthenticationEndpoint = (): string => {
  if (isProdEnv) {
    return process.env.REACT_APP_OAUTH_AUTHENTICATE_URL!;
  } else {
    return process.env.REACT_APP_OAUTH_AUTHENTICATE_DEV_URL!;
  }
};

export const getFirebaseProjectId = (): string => {
  return firebaseConfig.projectId;
};

log.info(`Firebase auth project: ${firebaseConfig.projectId}`);

export default firebaseConfig;
