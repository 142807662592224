import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import {sharedCommonActions} from '../../common/redux/SharedCommonActions';
import {getBusinessLine} from '../../core/configs/BusinessLineConfig';
import {getCurrentUserResponse} from '../../core/models/GetCurrentUser';
import {firestoreAuthService} from '../../core/services/FirestoreService';
import {getErrorMessage, logger} from '../../core/services/LogService';
import {ProducerReportJob} from '../models/ProducerReportJob';
import {ReportConfiguration} from '../models/ReportConfigurationModel';
import ReportJob from '../models/ReportJob';
import pixagriReportApiService from '../services/PixagriReportApiService';
import actions, {
  GENERATE_NOTIFICATION,
  GenerateNotificationAction,
  GET_PRODUCER_REPORT_JOB,
  GetProducerReportJobAction,
  LOAD_REPORTS,
  LOAD_REPORTS_CONFIG,
  LoadReportsAction,
  RETRY_REPORT_GENERATION,
  RetryReportGenerationAction,
  SET_SELECTED_REPORT,
  sharedReportActions,
} from './SharedReportActions';

export function* sharedReportsSagas() {
  yield all([
    takeEvery(LOAD_REPORTS_CONFIG, loadReportsConfig),
    takeLatest(LOAD_REPORTS, loadReports),
    takeEvery(SET_SELECTED_REPORT, setSelectedReport),
    takeEvery(RETRY_REPORT_GENERATION, retryReportGeneration),
    takeEvery(GENERATE_NOTIFICATION, generateNotification),
    takeLatest(GET_PRODUCER_REPORT_JOB, getProducerReportJob),
  ]);
}

function* getProducerReportJob(action: GetProducerReportJobAction) {
  try {
    yield put(sharedCommonActions.fetchStart('getProducerReportJob'));
    const {token}: getCurrentUserResponse = yield call(
      firestoreAuthService.getCurrentUser,
    );

    const producerReportJob: {[producer_ref: string]: ProducerReportJob} =
      yield call(
        pixagriReportApiService.getProducerReportJobs.bind(
          pixagriReportApiService,
        ),
        token.token,
        action.payload.campaignId,
        action.payload.type,
      );
    yield put(
      sharedReportActions.getProducerReportJobSuccess(producerReportJob),
    );
    yield put(sharedCommonActions.fetchSuccess('getProducerReportJob'));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error);
    logger.error(error);
    yield put(
      sharedCommonActions.fetchError('getProducerReportJob', errorMessage),
    );
  }
}

function* generateNotification(action: GenerateNotificationAction) {
  try {
    yield put(sharedCommonActions.fetchStart('generateNotification'));
    const {token}: getCurrentUserResponse = yield call(
      firestoreAuthService.getCurrentUser,
    );
    const {reportJob} = action.payload;

    yield call(
      pixagriReportApiService.generateNotification.bind(
        pixagriReportApiService,
      ),
      token.token,
      reportJob,
    );

    yield put(sharedCommonActions.fetchSuccess('generateNotification'));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    logger.error(errorMessage);
    yield put(
      sharedCommonActions.fetchError('generateNotification', errorMessage),
    );
  }
}

function* retryReportGeneration(action: RetryReportGenerationAction) {
  try {
    yield put(sharedCommonActions.fetchStart('retryReportGeneration'));
    const {reportId} = action.payload;
    const {token}: getCurrentUserResponse = yield call(
      firestoreAuthService.getCurrentUser,
    );
    const reportJob: ReportJob = yield call(
      //@ts-ignore
      pixagriReportApiService.retryReportGeneration.bind(
        pixagriReportApiService,
      ),
      token.token,
      getBusinessLine(),
      reportId,
    );
    yield put(
      sharedReportActions.loadReports(
        parseFloat(reportJob.campaign_id),
        reportJob.type,
      ),
    );
    yield put(sharedCommonActions.fetchSuccess('retryReportGeneration'));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    logger.error(errorMessage);
    yield put(
      sharedCommonActions.fetchError('retryReportGeneration', errorMessage),
    );
  }
}
//Sends api request to Redux store
function* loadReportsConfig() {
  try {
    yield put(sharedCommonActions.fetchStart('loadReportsConfig'));

    const {token}: getCurrentUserResponse = yield call(
      firestoreAuthService.getCurrentUser,
    );
    const reportConfigurations: {
      [reportConfigurationRef: string]: ReportConfiguration;
    } = yield call(
      pixagriReportApiService.getReportConfigurations.bind(
        pixagriReportApiService,
      ),
      token.token,
    );

    if (!reportConfigurations) {
      throw new Error('Failed to load reports config');
    }

    yield put(actions.loadReportsConfigsSuccess(reportConfigurations));
    yield put(sharedCommonActions.fetchSuccess('loadReportsConfig'));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error);
    yield put(
      sharedCommonActions.fetchError('loadReportsConfig', errorMessage),
    );
  }
}

function* loadReports(action: LoadReportsAction) {
  try {
    yield put(sharedCommonActions.fetchStart('loadReports'));
    const {campaignId, type, producer_ref, limit, date} = action.payload;
    const {token}: getCurrentUserResponse = yield call(
      firestoreAuthService.getCurrentUser,
    );

    const reports: {
      [ref: string]: ReportJob;
    } = yield call(
      pixagriReportApiService.getReportJobs.bind(pixagriReportApiService),
      token.token,
      campaignId,
      type,
      producer_ref,
      limit,
      date,
    );

    if (!reports) {
      throw new Error('Failed to load reports');
    }

    yield put(actions.loadReportsSuccess(reports));
    yield put(sharedCommonActions.fetchSuccess('loadReports'));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error);
    yield put(sharedCommonActions.fetchError('loadReports', errorMessage));
  }
}

function* setSelectedReport(report: ReportJob) {
  try {
    yield put(sharedCommonActions.fetchStart('setSelectedReport'));
    yield put(actions.setSelectedReport(report));
    yield put(sharedCommonActions.fetchSuccess('setSelectedReport'));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error);
    yield put(sharedCommonActions.fetchError('loadReports', errorMessage));
  }
}
