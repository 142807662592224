import {ProducerReportJob} from '../models/ProducerReportJob';
import {ReportConfiguration} from '../models/ReportConfigurationModel';
import ReportJob from '../models/ReportJob';
import ReportJobStatus from '../models/ReportJobStatus';
import ReportJobType from '../models/ReportJobType';

export const LOAD_REPORTS_CONFIG = 'LOAD_REPORTS_CONFIG';
export interface LoadReportsConfigAction {
  type: typeof LOAD_REPORTS_CONFIG;
}

export const LOAD_REPORTS_CONFIG_SUCCESS = 'LOAD_REPORTS_CONFIG_SUCCESS';
export interface LoadReportsConfigSuccessAction {
  type: typeof LOAD_REPORTS_CONFIG_SUCCESS;
  payload: {[key: string]: ReportConfiguration};
}

export const LOAD_REPORTS = 'LOAD_REPORTS';
export interface LoadReportsAction {
  type: typeof LOAD_REPORTS;
  payload: {
    campaignId: number;
    type: ReportJobType;
    producer_ref?: string;
    status?: ReportJobStatus[];
    limit?: number;
    date?: Date;
  };
}

export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export interface LoadReportsSuccessAction {
  type: typeof LOAD_REPORTS_SUCCESS;
  payload: {[key: string]: ReportJob};
}

export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';
export interface SetSelectedReportAction {
  type: typeof SET_SELECTED_REPORT;
  payload: ReportJob;
}

export const RETRY_REPORT_GENERATION = 'RETRY_REPORT_GENERATION';
export interface RetryReportGenerationAction {
  type: typeof RETRY_REPORT_GENERATION;
  payload: {
    reportId: string;
  };
}

export const GENERATE_NOTIFICATION = 'GENERATE_NOTIFICATION';
export interface GenerateNotificationAction {
  type: typeof GENERATE_NOTIFICATION;
  payload: {
    reportJob: ReportJob;
  };
}

export const GET_PRODUCER_REPORT_JOB = 'GET_PRODUCER_REPORT_JOB';
export interface GetProducerReportJobAction {
  type: typeof GET_PRODUCER_REPORT_JOB;
  payload: {
    campaignId: number;
    type: ReportJobType;
  };
}

export const GET_PRODUCER_REPORT_JOB_SUCCESS =
  'GET_PRODUCER_REPORT_JOB_SUCCESS';
export interface GetProducerReportJobSuccessAction {
  type: typeof GET_PRODUCER_REPORT_JOB_SUCCESS;
  payload: {
    [producer_ref: string]: ProducerReportJob;
  };
}

export type ReportActionTypes =
  | LoadReportsConfigAction
  | LoadReportsConfigSuccessAction
  | LoadReportsAction
  | LoadReportsSuccessAction
  | SetSelectedReportAction
  | RetryReportGenerationAction
  | GenerateNotificationAction
  | GetProducerReportJobSuccessAction
  | GetProducerReportJobSuccessAction;

export const sharedReportActions = {
  getProducerReportJob: (
    campaignId: number,
    type: ReportJobType,
  ): GetProducerReportJobAction => ({
    type: GET_PRODUCER_REPORT_JOB,
    payload: {
      campaignId,
      type,
    },
  }),
  getProducerReportJobSuccess: (data: {
    [producer_ref: string]: ProducerReportJob;
  }): GetProducerReportJobSuccessAction => ({
    type: GET_PRODUCER_REPORT_JOB_SUCCESS,
    payload: data,
  }),
  generateNotification: (reportJob: ReportJob): GenerateNotificationAction => ({
    type: GENERATE_NOTIFICATION,
    payload: {reportJob},
  }),
  retryReportGeneration: (reportId: string): RetryReportGenerationAction => ({
    type: RETRY_REPORT_GENERATION,
    payload: {reportId},
  }),
  loadReportsConfig: (): LoadReportsConfigAction => ({
    type: LOAD_REPORTS_CONFIG,
  }),

  loadReportsConfigsSuccess: (config: {
    [key: string]: ReportConfiguration;
  }): LoadReportsConfigSuccessAction => ({
    type: LOAD_REPORTS_CONFIG_SUCCESS,
    payload: config,
  }),

  loadReports: (
    campaignId: number,
    reportType: ReportJobType,
    producer_ref?: string,
    limit?: number,
    date?: Date,
  ): LoadReportsAction => ({
    type: LOAD_REPORTS,
    payload: {
      campaignId,
      type: reportType,
      producer_ref,
      limit,
      date,
    },
  }),

  loadReportsSuccess: (reports: {
    [key: string]: ReportJob;
  }): LoadReportsSuccessAction => ({
    type: LOAD_REPORTS_SUCCESS,
    payload: reports,
  }),

  setSelectedReport: (report: ReportJob) => ({
    type: SET_SELECTED_REPORT,
    payload: report,
  }),
};

export default sharedReportActions;
