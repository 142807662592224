enum NotificationStatus {
    PENDING = 'pending',
    SENDING = 'sending',
    SENT = 'sent',
    FAILED = 'failed',
    /**
     * Seen by user when applicable
     */
    CONFIRMED = 'confirmed',
    SKIPPED = 'skipped',
    CANCELLED = 'cancelled',
}
export default NotificationStatus;
