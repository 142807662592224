import ReportState from './ReportState';
import {
  GET_PRODUCER_REPORT_JOB_SUCCESS,
  LOAD_REPORTS_CONFIG_SUCCESS,
  LOAD_REPORTS_SUCCESS,
  ReportActionTypes,
  SET_SELECTED_REPORT,
} from './SharedReportActions';

const initialState: ReportState = {
  config: null,
  selectedReportJob: null,
  report_job_data: null,
  producer_report_job: null,
};

const sharedReportReducers = (
  state: ReportState = initialState,
  action: ReportActionTypes,
) => {
  switch (action.type) {
    case GET_PRODUCER_REPORT_JOB_SUCCESS:
      return {
        ...state,
        producer_report_job: {
          ...action.payload,
        },
      };
    case LOAD_REPORTS_CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          ...action.payload,
        },
      };
    case LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        report_job_data: {
          ...action.payload,
        },
      };
    case SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReportJob: action.payload,
      };
    default:
      return state;
  }
};

export default sharedReportReducers;
