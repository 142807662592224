import PixagriScopes from '../../core/models/PixagriScopes';
import TerranisBusinessLines from '../../core/models/TerranisBusinessLines';
import {BaseReportApiService} from '../../core/services/apiServices/BaseReportApiService';
import {ProducerReportJob} from '../models/ProducerReportJob';
import {ReportConfiguration} from '../models/ReportConfigurationModel';
import ReportJob from '../models/ReportJob';
import ReportJobType from '../models/ReportJobType';
import ReportQueriesKey from '../models/ReportQueriesKey';

export class PixagriReportApiService extends BaseReportApiService {
  GET_REPORT_JOB_ENDPOINT = '/jobs';
  GET_PRODUCER_REPORT_JOB_ENDPOINT = `${this.GET_REPORT_JOB_ENDPOINT}/producer`;
  GET_REPORT_CONFIGURATIONS_ENDPOINT = '/configurations';

  NOTIFY_REPORT_JOB_ENDPOINT = '/notify';
  GENERATE_NOTIFICATION = '/generate_notification';
  REPORT_JOB_ID_PARAM_KEY = 'reportJobId';
  REPORT_JOB_ID_PARAM = `:${this.REPORT_JOB_ID_PARAM_KEY}`;

  getRetryReportGenerationPath() {
    return `${this.REPORT_JOB_ID_PARAM}/retry`;
  }
  getRetryReportGenerationUrl(businessLine: string, reportJobId: string) {
    return `${this.getBaseUrl()}/${
      PixagriScopes.REPORT
    }/${this.getRetryReportGenerationPath().replace(
      this.REPORT_JOB_ID_PARAM,
      reportJobId,
    )}`;
  }
  getNotyfyReportJobUrl() {
    return `${this.getBaseUrl()}/${PixagriScopes.REPORT}${
      this.NOTIFY_REPORT_JOB_ENDPOINT
    }`;
  }
  getGenerateNotificationUrl() {
    return `${this.getBaseUrl()}/${PixagriScopes.REPORT}${
      this.GENERATE_NOTIFICATION
    }`;
  }
  getGetReportJobsUrl(
    campaignId: number,
    reportJobType: ReportJobType,
    producer_ref?: string,
    limit?: number,
    date?: Date,
  ) {
    let baseUrl = `${this.getBaseUrl()}/${PixagriScopes.REPORT}${
      this.GET_REPORT_JOB_ENDPOINT
    }`;
    if (campaignId) {
      baseUrl += `?${ReportQueriesKey.CAMPAIGN_YEAR_QUERY}=${campaignId}`;
    }
    if (reportJobType) {
      baseUrl += `&${ReportQueriesKey.ReportJobType}=${reportJobType}`;
    }
    if (producer_ref) {
      baseUrl += `&${ReportQueriesKey.PRODUCER_REF}=${producer_ref}`;
    }
    if (limit) {
      baseUrl += `&${ReportQueriesKey.LIMIT}=${limit}`;
    }
    if (date) {
      baseUrl += `&${ReportQueriesKey.DATE}=${date}`;
    }
    return baseUrl;
  }
  getGetProducerReportJobsUrl(
    campaignId: number,
    reportJobType: ReportJobType,
  ) {
    let baseUrl = `${this.getBaseUrl()}/${PixagriScopes.REPORT}${
      this.GET_PRODUCER_REPORT_JOB_ENDPOINT
    }`;
    if (campaignId) {
      baseUrl += `?${ReportQueriesKey.CAMPAIGN_YEAR_QUERY}=${campaignId}`;
    }
    if (reportJobType) {
      baseUrl += `&${ReportQueriesKey.ReportJobType}=${reportJobType}`;
    }
    return baseUrl;
  }

  getGetReportConfigurationsUrl() {
    return `${this.getBaseUrl()}/${PixagriScopes.REPORT}${
      this.GET_REPORT_CONFIGURATIONS_ENDPOINT
    }`;
  }

  async generateNotification(token: string, report: ReportJob) {
    const url = this.getGenerateNotificationUrl();
    const response = await this.sendRequest(token, url, 'POST', report);
    if (response.error) {
      throw new Error(response.error_message);
    } else {
      return response.response as ReportJob;
    }
  }

  async retryReportGeneration(
    token: string,
    businessLine: TerranisBusinessLines,
    reportJobId: string,
  ) {
    const url = this.getRetryReportGenerationUrl(businessLine, reportJobId);
    const response = await this.sendRequest(token, url, 'POST');
    if (response.error) {
      throw new Error(response.error_message);
    } else {
      return response.response as ReportJob;
    }
  }
  async getReportJobs(
    token: string,
    campaignId: number,
    reportJobType: ReportJobType,
    producer_ref?: string,
    limit?: number,
    date?: Date,
  ) {
    const url = this.getGetReportJobsUrl(
      campaignId,
      reportJobType,
      producer_ref,
      limit,
      date,
    );
    const response = await this.sendRequest(token, url, 'GET');
    if (response.error) {
      throw new Error(response.error_message);
    } else {
      return response.response as {[ref: string]: ReportJob};
    }
  }
  async getProducerReportJobs(
    token: string,
    campaignId: number,
    reportJobType: ReportJobType,
  ): Promise<{[producer_ref: string]: ProducerReportJob}> {
    const url = this.getGetProducerReportJobsUrl(campaignId, reportJobType);
    const response = await this.sendRequest<ProducerReportJob[]>(
      token,
      url,
      'GET',
    );
    if (response.error) {
      throw new Error(response.error_message);
    } else {
      const producerReportJobs: {[producer_ref: string]: ProducerReportJob} =
        {};
      response.response.forEach((job) => {
        producerReportJobs[job.producer_ref] = job;
      });
      return producerReportJobs;
    }
  }
  async getReportConfigurations(token: string) {
    const url = this.getGetReportConfigurationsUrl();
    const response = await this.sendRequest(token, url, 'GET');
    if (response.error) {
      throw new Error(response.error_message);
    } else {
      const reportConfiguration: {
        [reportConfigurationREf: string]: ReportConfiguration;
      } = {};
      const configurations = response.response as ReportConfiguration[];
      configurations.forEach((conf) => {
        reportConfiguration[conf.ref] = conf;
      });
      return reportConfiguration;
    }
  }
}
const pixagriReportApiService = new PixagriReportApiService();
export default pixagriReportApiService;
